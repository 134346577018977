<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="$wait.is('loading-stores')">
        <div class="alert alert-info" key="loading">
          <h2>
            <cs-loading theme="info">
              Loading {{ titleCase(plural(program.not_course_session_label, 2)) }}
            </cs-loading>
          </h2>
        </div>
      </div>
      <div id="stores" v-else key="loaded">
        <div v-if="stores.length > 0" key="stores" class="card w-100">
          <div class="card-header">
            <h3>{{ titleCase(plural(program.not_course_session_label, stores.length)) }} for <span v-html="program.name"/></h3>
          </div>
          <div class="card-body">
            <nav v-if="stores.length > 1" class="nav nav-pills flex-column flex-sm-row">
              <button
                  v-for="store in stores"
                  :key="store.id"
                  :class="{
                  'bg-success text-light font-weight-bold':
                    (selectedStore == null ||
                      store.id === selectedStore.id) &&
                    (store.registrationOpen || store.earlyRegistrationOpen),
                  'bg-success-light':
                    (store.registrationOpen ||
                      store.earlyRegistrationOpen) &&
                    !(
                      selectedStore == null ||
                      store.id === selectedStore.id
                    ),
                  'bg-info text-light font-weight-bold':
                    !(
                      store.registrationOpen || store.earlyRegistrationOpen
                    ) &&
                    (selectedStore == null ||
                      store.id === selectedStore.id),
                  'bg-info-light':
                    !(
                      store.registrationOpen || store.earlyRegistrationOpen
                    ) &&
                    !(
                      selectedStore == null ||
                      store.id === selectedStore.id
                    )
                }"
                  v-on:click.prevent="selectStore(store)"
                  class="flex-sm-fill text-sm-center nav-link"
              >
                <span v-html="store.name"></span>
                <span v-if="showOpenBadge(store)"
                      class="ml-2 badge badge-primary">OPEN</span>
                <br/>
                <span class="text-dark">
                  {{ dateRange(store.startDate, store.endDate,'-' , 'MMM do') }}
                </span>
              </button>
            </nav>
            <store-session
                id="session"
                v-if="selectedStore !== null && selectedStore.id !== 0"
                :store="selectedStore"
                :loggedIn="loggedIn"
            ></store-session>
          </div>
        </div>
        <div v-else class="card w-100 border-warning">
          <div class="card-header bg-warning">
            <h4>No Stores open for <span v-html="program.name"/></h4>
          </div>
          <div class="card-body bg-warning-light">
            <p class="lead">
              Please check with the {{ program.admin_name }} for more
              information
              <a
                  v-bind:href="'mailto:' + program.admin_email"
                  class="btn btn-info"
              >
                <font-awesome-icon icon="envelope"/>
                {{
                  program.admin_name
                }}</a
              >
              {{ program.phone | phone }} for more information
            </p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import StoreSession from '@/components/calendars_sessions/stores/StoreSession.vue'
import { UPDATE_SESSIONS, UPDATE_STORE } from '@/constants/mutation-types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { dateRange } from '@/utilities/dateFormatters'
import { plural, titleCase } from '@/utilities/stringFormatters'

export default {
  name: 'StoreSessions',
  props: {
    storeSessionId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {}
  },
  components: {
    StoreSession
  },
  created () {
    this.$wait.start('loading-stores')
    this.$store.dispatch(UPDATE_SESSIONS, {})
      .then(result => {
        this.initStoreFromSearchParams()
        this.$wait.end('loading-stores')
        if (typeof this.storeSessionId !== 'undefined' && this.storeSessionId !== '' && this.storeSessionId !== null) {
          const found = this.stores.find(store => {
            return this.storeSessionId.toString() === store.id.toString()
          })
          if (found !== null) {
            this.selectStore(found)
          }
        }
        console.log('result', result)
      })
      .catch(e => {
        console.error('error loading sessions', e)
        this.$wait.end('loading-stores')
      })
  },
  watch: {
    stores (newValue, oldValue) {
      console.log('stores', this.stores, this.stores.length)
      if (this.stores.length === 1) {
        this.selectStore(this.stores[0])
      }
    }
  },
  computed: {
    ...mapGetters({
      registration: 'registration',
      sessions: 'sessions',
      sessionsStatus: 'sessionsStatus',
      selectedStore: 'selectedStore',
      students: 'students',
      program: 'program',
      loggedIn: 'loggedIn',
      member: 'registrationMember'
    }),
    stores () {
      return this.sessions.filter(session => {
        return session.not_courses
      })
    }
  },
  methods: {
    plural,
    titleCase,
    ...mapMutations([UPDATE_SESSIONS, 'sessionsLoadingStatus']),
    ...mapActions([UPDATE_STORE]),
    dateRange,
    selectStore: function (store) {
      this.$wait.start('loading-store')
      this.$emit('update:store-session-id', store.id)
      this.updateStore(store)
        .then(() => {
          this.$wait.end('loading-store')
        })
        .catch(() => {
          this.$wait.end('loading-store')
        })
      const vm = this
      this.$nextTick(function () {
        vm.$scrollTo('#store')
      })
    },
    initStoreFromSearchParams () {
      const url = new URL(window.location.href)
      const searchParams = url.searchParams
      if (searchParams.has('store_session_id')) {
        const store = this.stores.find(store => {
          return parseInt(store.id) === parseInt(searchParams.get('store_session_id'))
        })
        if (!isEmpty(store)) {
          this.selectStore(store)
        }
      }
    },
    showOpenBadge (store) {
      if (store.registrationOpen) {
        return true
      }
      const earlyRoles = ['staff', 'program', 'admin']
      return !!(this.loggedIn && !isEmpty(this.member) && !isEmpty(this.member.role) &&
          earlyRoles.includes(this.member.role) &&
          store.earlyRegistrationOpen)
    }
  }
}
</script>

<style scoped>
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
</style>
