<template>
  <div
      :id="'session-' + store.id"
      class="card w-100 px-0 mx-0"
      :class="{
      'border-success': store.registrationOpen,
      'border-warning': !store.registrationOpen
    }"
  >
    <div
        class="card-header"
        :class="{
        'bg-success-light': store.registrationOpen,
        'bg-warning-light': !store.registrationOpen
      }"
    >
      <h4>
        <span v-html="store.name"></span>
        <small class="float-right"
        >{{ dateRange(store.startRegistration, store.endRegistration, '-', 'MMM do yy') }}
        </small>
      </h4>
    </div>
    <div class="card-body">
      <h5 v-if="store.registrationOpen" class="card-title">
        Store Closes:
        {{ dateTimeString(store.endRegistration) }}
      </h5>
      <h5 v-else class="card-title">
        Store Dates:
        {{ dateTimeRange(store.beginRegistration, store.endRegistration) }}
      </h5>

      <p class="lead">Available to registrations with students from {{ store.school_names }}</p>
    </div>

    <div class="list-group list-group-flush">
      <div
          v-if="$wait.is('loading-courses')"
          class="list-group-item list-group-item-info"
      >
        <h2>
          <div class="spinner-grow text-primary text-center" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          Updating Items
        </h2>
      </div>
      <transition>
        <div id="store-items" v-if="this.items.length > 0" class="list-group-item">
          <h5>Items</h5>
          <div class="row row-cols-md-3 row-cols-lg-4">
            <store-item v-for="item in this.items"
                        :item="item"
                 class="col"
                 :key="`store_item_${item.id}`">
              {{ item.name }}
            </store-item>
          </div>

        </div>
      </transition>
    </div>
    <div class="card-footer" v-if="!$wait.is('loading-courses')">
      <nav class="nav nav-pills flex-column flex-sm-row">
        <a
            class="flex-sm-fill text-sm-center nav-link disabled"
            href="#"
            tabindex="-1"
            aria-disabled="true"
        >Scroll up to</a
        >
        <button
            class="flex-sm-fill text-sm-center nav-link bg-info-light"
            v-scroll-to="'#registration'"
        >
          Registration
        </button>
        <button
            class="flex-sm-fill text-sm-center nav-link bg-info-light"
            v-scroll-to="'#session-store-' + store.id"
        >
          Top <span v-html="store.name"></span>
        </button>
      </nav>
    </div>
  </div>
</template>

<script>
import StoreItem from '@/components/calendars_sessions/stores/StoreItem'
import { groupMethods } from '@/components/mixins/group'
import { courseMethods } from '@/components/mixins/course'
import { mapGetters } from 'vuex'
import { dateRange, dateString, dateTimeRange, dateTimeString } from '@/utilities/dateFormatters'
import { currency } from '@/utilities/stringFormatters'

export default {
  name: 'StoreSession',
  components: {
    StoreItem
  },
  mixins: [groupMethods, courseMethods],
  props: {
    store: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  created () {},
  computed: {
    ...mapGetters({
      year: 'year',
      program: 'program',
      registration: 'registration',
      status: 'sessionStatus',
      students: 'students',
      loggedIn: 'loggedIn'
    }),
    hasItems: function () {
      return this.items.length > 0
    },
    items: function () {
      if (typeof this.store.courses !== 'undefined') {
        var courses = this.store.courses
        var result = courses.filter(function (item) {
          return item.not_course
        })
        return result
      }
      return []
    }
  },
  methods: {
    currency,
    dateRange,
    dateTimeRange,
    dateTimeString,
    dateString
  }
}
</script>

<style scoped></style>
