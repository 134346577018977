var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$wait.is('loading-stores'))?_c('div',[_c('div',{key:"loading",staticClass:"alert alert-info"},[_c('h2',[_c('cs-loading',{attrs:{"theme":"info"}},[_vm._v("\n            Loading "+_vm._s(_vm.titleCase(_vm.plural(_vm.program.not_course_session_label, 2)))+"\n          ")])],1)])]):_c('div',{key:"loaded",attrs:{"id":"stores"}},[(_vm.stores.length > 0)?_c('div',{key:"stores",staticClass:"card w-100"},[_c('div',{staticClass:"card-header"},[_c('h3',[_vm._v(_vm._s(_vm.titleCase(_vm.plural(_vm.program.not_course_session_label, _vm.stores.length)))+" for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[(_vm.stores.length > 1)?_c('nav',{staticClass:"nav nav-pills flex-column flex-sm-row"},_vm._l((_vm.stores),function(store){return _c('button',{key:store.id,staticClass:"flex-sm-fill text-sm-center nav-link",class:{
                'bg-success text-light font-weight-bold':
                  (_vm.selectedStore == null ||
                    store.id === _vm.selectedStore.id) &&
                  (store.registrationOpen || store.earlyRegistrationOpen),
                'bg-success-light':
                  (store.registrationOpen ||
                    store.earlyRegistrationOpen) &&
                  !(
                    _vm.selectedStore == null ||
                    store.id === _vm.selectedStore.id
                  ),
                'bg-info text-light font-weight-bold':
                  !(
                    store.registrationOpen || store.earlyRegistrationOpen
                  ) &&
                  (_vm.selectedStore == null ||
                    store.id === _vm.selectedStore.id),
                'bg-info-light':
                  !(
                    store.registrationOpen || store.earlyRegistrationOpen
                  ) &&
                  !(
                    _vm.selectedStore == null ||
                    store.id === _vm.selectedStore.id
                  )
              },on:{"click":function($event){$event.preventDefault();return _vm.selectStore(store)}}},[_c('span',{domProps:{"innerHTML":_vm._s(store.name)}}),_vm._v(" "),(_vm.showOpenBadge(store))?_c('span',{staticClass:"ml-2 badge badge-primary"},[_vm._v("OPEN")]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"text-dark"},[_vm._v("\n                "+_vm._s(_vm.dateRange(store.startDate, store.endDate,'-' , 'MMM do'))+"\n              ")])])}),0):_vm._e(),_vm._v(" "),(_vm.selectedStore !== null && _vm.selectedStore.id !== 0)?_c('store-session',{attrs:{"id":"session","store":_vm.selectedStore,"loggedIn":_vm.loggedIn}}):_vm._e()],1)]):_c('div',{staticClass:"card w-100 border-warning"},[_c('div',{staticClass:"card-header bg-warning"},[_c('h4',[_vm._v("No Stores open for "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.program.name)}})])]),_vm._v(" "),_c('div',{staticClass:"card-body bg-warning-light"},[_c('p',{staticClass:"lead"},[_vm._v("\n            Please check with the "+_vm._s(_vm.program.admin_name)+" for more\n            information\n            "),_c('a',{staticClass:"btn btn-info",attrs:{"href":'mailto:' + _vm.program.admin_email}},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}}),_vm._v("\n              "+_vm._s(_vm.program.admin_name))],1),_vm._v("\n            "+_vm._s(_vm._f("phone")(_vm.program.phone))+" for more information\n          ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }